import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import GatsbyImage from "gatsby-image";
import ImageLightBox from "../atoms/ImageLightBox";

const Holder = styled.div`
  cursor: pointer;
`;

function ImageWithLightbox({fluid, description}) {
  const [show, setShow] = useState(false);
  if (!fluid) return null;
  return (
    <>
      <Holder className="image-holder" onClick={() => setShow(true)}>
        <GatsbyImage fluid={fluid} />
      </Holder>
      <ImageLightBox
        show={show}
        fluid={fluid}
        caption={description}
        closeHandler={() => setShow(false)}/>
    </>
  )
}

ImageWithLightbox.propTypes = {
  fluid: PropTypes.object,
  description: PropTypes.string,
};

export default ImageWithLightbox;