import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {CSSTransition} from "react-transition-group";
import GatsbyImage from "gatsby-image";

const Holder = styled.div`
  flex-direction: column;
  position: fixed;
  z-index: 100;
  background-color: ${props => props.theme.colours.black};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;

  &.lightbox-appear,
  &.lightbox-enter {
    opacity: 0;
  }
  &.lightbox-appear-active, &.lightbox-appear-done,
  &.lightbox-enter-active, &.lightbox-enter-done {
    opacity: 1;
    transition: opacity 0.5s;
  }
  &.lightbox-exit {
    opacity: 1;
  }
  &.lightbox-exit-active {
    opacity: 0;
    transition: opacity 0.5s;
  }
`;

const Inner = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    filter: ${(props) => (props.blur ? 'blur(30px)' : 'none')};
    transition: filter 0.25s;

    .close-button-holder {
        position: absolute;
        top: 1rem;
        left: 1rem;
        margin: 0;
        z-index: 10;
        mix-blend-mode: difference;
    }
`;

const Caption = styled.p`
    width: calc(100% - 2rem);
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    margin: 0;
    mix-blend-mode: difference;
`;

function ImageLightBox({closeHandler, show, fluid, caption}) {
  const [ windowRatio, setWindowRatio ] = useState( null );
  useEffect(() => {
    const isBrowser = () => typeof window !== "undefined"
    if( isBrowser() ) {
      setWindowRatio(window.innerWidth / window.innerHeight);
    }
  }, []);
  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear
      in={show}
      timeout={500}
      classNames="lightbox">
      <Holder>
        <Inner>
          <p className="close-button-holder">
            <button
              className="link"
              title="Close light-box"
              aria-label="Close light-box"
              onClick={() => {
                closeHandler();
              }}
            >
              Close
            </button>
          </p>
          <GatsbyImage
            style={{
              width: '100%',
              height: '100%',
            }}
            imgStyle={{
              objectFit:
                (windowRatio < 1 &&
                  fluid.aspectRatio < 1) ||
                (windowRatio > 1 &&
                  fluid.aspectRatio > 1)
                  ? 'cover'
                  : 'contain',
            }}
            fluid={fluid}
          />
          {caption && <Caption>{caption}</Caption>}
        </Inner>
      </Holder>
    </CSSTransition>
  )
}

ImageLightBox.propTypes = {
  show: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  fluid: PropTypes.object.isRequired,
  caption: PropTypes.string,
};

export default ImageLightBox;