import React from 'react';
import {graphql, Link} from 'gatsby';
import styled from 'styled-components';
import Seo from '../components/molecules/Seo';
import queryString from 'query-string';
import GatsbyImage from "gatsby-image";
import RichTextConverter from "../components/atoms/RichTextConverter";
import BackArrow from '../assets/back-arrow.inline.svg';

const Holder = styled.div`
  ${(props) => props.theme.fluidType(-3)};
`;

const Hero = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  video,
  .gatsby-image-wrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video { object-fit: cover; }

  h1 {
    position: absolute;
    z-index: 2;
    top: 1rem;
    left: 2rem;
    margin: 0;
    width: calc(100% - 4rem);
    text-transform: uppercase;
    text-align: center;
    ${(props) => props.theme.fluidType(6)};
    mix-blend-mode: difference;
    letter-spacing: 0.2rem;
  }
`;

const Intro = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  max-width: 40rem;
  margin: 2rem auto;
  padding: 0 1rem;
  grid-auto-flow: dense;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin: 4rem auto;
    grid-template-columns: 1fr 2fr;
    .intro-text { grid-column: 2/3; }

    .credits-text {
      grid-column: 1/2;
    }
  }
`;

const Content = styled.div`
  margin-bottom: 6rem;
  p, h2, blockquote, .image-holder {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem;
  }
  
  h2 {
    ${(props) => props.theme.fluidType(8)};
    margin-top: 4rem;
    margin-bottom: 4rem;
    @media( ${props => props.theme.breakpoints.md} ) {
      margin-top: 6rem;
      margin-bottom: 6rem;
    }
  }

  p {
    @media ( ${props => props.theme.breakpoints.md} ) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }
  }

  blockquote p {
    display: block;
    padding: 0;
  }

  .image-holder {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .video-holder {
    .caption { display: none; }
  }
`;

const BackLink = styled.div`
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 10;
  mix-blend-mode: difference;
  svg {
    height: 0.8rem;
    width: auto;
    display: block;
  }
`;

const Page = ({data, location}) => {
  const {title, credits, intro, mainContent,} = data.contentfulProject;
  const parsed = queryString.parse(location.search);
  const assets = data.allContentfulProjectPreviewAsset.nodes;
  const heroAsset = assets.find(node => node.id === parsed.heroasset);
  return (
    <Holder>
      <Seo title={title} />
      <BackLink>
        <Link to="/" title="Back to home"><BackArrow/></Link>
      </BackLink>
      <Hero>
        <h1>{title}</h1>
        {heroAsset && heroAsset.asset.file && heroAsset.asset.file.contentType.includes('video') &&
        <video muted
               loop
               playsInline
               autoPlay
               controls={false}
               poster={heroAsset.videoPosterImage && heroAsset.videoPosterImage.fixed.src}>
          <source src={heroAsset.asset.file.url} type={heroAsset.asset.file.contentType} />
          Your browser does not support the video tag.
        </video>}

        {heroAsset && heroAsset.asset.fluid && <GatsbyImage
          fluid={heroAsset.asset.fluid} />}
      </Hero>
      <Intro>
        <div className="intro-text"><RichTextConverter content={intro} /></div>
        <div className="credits-text"><RichTextConverter content={credits} /></div>
      </Intro>
      <Content>
        <RichTextConverter content={mainContent} />
      </Content>
    </Holder>
  );
}

export default Page;

export const pageQuery = graphql`
    query($id: String!) {
        contentfulProject(id: { eq: $id }) {
            title
            credits {
              raw
            }
            mainContent {
              raw
              references {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  file {
                    contentType
                    url
                  }
                  description
                  fluid(maxWidth: 4000) {
                    ...GatsbyContentfulFluid_noBase64
                  }
                }
                ... on ContentfulTwoUpImageGrid {
                  contentful_id
                  __typename
                  images {
                    id
                    description
                    fluid(maxWidth: 4000) {
                      ...GatsbyContentfulFluid_noBase64
                    }
                  }
                }
                ... on ContentfulEmbed {
                  contentful_id
                  __typename
                  embedCode {
                    id
                    embedCode
                  }
                }
              }
            }
            intro {
              raw
            }
        }
        allContentfulProjectPreviewAsset(filter: {project: {id: {eq: $id}}}) {
          nodes {
            id
            asset {
              fluid(maxWidth: 4000) {
                aspectRatio
                ...GatsbyContentfulFluid_noBase64
              }
              file {
                contentType
                url
              }
            }
            videoPosterImage {
              fixed(width: 1200, quality: 90) {
                src
              }
            }
          }
        }
    }
`;
