import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import ImageWithLightbox from "./ImageWithLightbox";

const Holder = styled.div`
  max-width: 40rem;
  margin: 4rem auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  @media( ${props => props.theme.breakpoints.sm} ) {
    grid-template-columns: 1fr 1fr;
    margin: 6rem auto;
    grid-row-gap: 6rem;
  }
  p {
    padding: 0;
    margin: 0.5rem 0 0 0;
  }
  .image-holder {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

function TwoUpImages({images}) {
  if(!images) return null;
  return (
    <Holder>
      {images.map(image => <div key={image.id}>
        {image.fluid && <ImageWithLightbox fluid={image.fluid} description={image.description}/>}
      </div>)}
    </Holder>
  )
}

TwoUpImages.propTypes = {
  images: PropTypes.array.isRequired,
};

export default TwoUpImages;