import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import styled from 'styled-components';
import TwoUpImages from "../molecules/TwoUpImages";
import MarkdownConverter from "./MarkdownConverter";
import EmbedContainer from "./EmbedContainer";
import ImageWithLightbox from "../molecules/ImageWithLightbox";
import _uniqueId from 'lodash/uniqueId';

const Holder = styled.div`
  width: 100%;
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

class RichTextConverter extends Component {
  render() {
    const { content } = this.props;

    const options = {
      renderNode: {
        [ BLOCKS.PARAGRAPH ]: (node, children) => <p><span>{children}</span><span/></p>,
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
          // console.log(node);
          if( node.data.target.__typename === 'ContentfulTwoUpImageGrid') {
            return <TwoUpImages images={node.data.target.images}/>
          } else if( node.data.target.__typename === 'ContentfulEmbed') {
            return <EmbedContainer><MarkdownConverter content={node.data.target.embedCode.embedCode}/></EmbedContainer>
          }
        },
        [ BLOCKS.EMBEDDED_ASSET ]: ( node ) => {
          // console.log(node.data);
          let {file, fluid, description} = node.data.target
          if( file.contentType.includes( 'video' ) ) {
            return <div className="video-holder">
              <video controls>
                <source src={file.url} type={file.contentType}/>
              </video>
              {description && <p className="caption">{description}</p>}
            </div>
          } else if( fluid ) {
            return (
              <ImageWithLightbox fluid={fluid} description={description} />
            )
          }
        },
      },
      renderText: text =>
        text.split("\n").flatMap((text, i) => [i > 0 && <br key={_uniqueId()}/>, <React.Fragment key={_uniqueId()}>{text}</React.Fragment>])
    };

    return (
      <Holder classNames="rich-text-holder">{renderRichText( content, options )}</Holder>
    )
  }
}

RichTextConverter.propTypes = {
  content: PropTypes.object
};

export default RichTextConverter;
